<template>
  <v-container>
    <snackbar ref="snackbar"></snackbar>
    <v-btn icon @click="getDocs"><v-icon>mdi-printer</v-icon> </v-btn>

    <v-dialog v-model="printDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>PRINTING ERROR</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="printDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12"> Are you sure want you want to delete? </v-col>
              <v-col cols="12">
                <v-btn color="red" dark @click.native="printDialog = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--      //fiscalization error dialog start-->
    <v-dialog v-model="fiscalizeErrorDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="red" dark>
          <v-toolbar-title>PRINTING ERROR</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="fiscalizeErrorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                {{ errorMsg }}
              </v-col>
              <v-col cols="6">
                <v-btn color="red" dark @click.native="printDialog = false">close</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="green" dark @click.native="printDoc">Yes Preview</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--      //fiscalization error dialog end-->

    <!--      //fiscalization success dialog start-->
    <v-dialog v-model="fiscalizeSuccessDialog" max-width="650px">
      <v-card>
        <v-toolbar dense color="green" dark>
          <v-toolbar-title>PRINTING SUCCESS</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.native="fiscalizeSuccessDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                {{ successMsg }}
              </v-col>
              <v-col cols="12">
                <v-btn color="green" dark @click.native="getDocs">Print</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--      //fiscalization success dialog end-->
  </v-container>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import axios from "axios";
export default {
  data: () => ({
    record: [],
    tableData: [],
    activeObject: null,
    activeDocumentID: null,
    printDialog: false,
    fiscalizeErrorDialog: false,
    fiscalizeSuccessDialog: false,
    errorMsg: null,
    successMsg: null,
    defaultSettings: {},
    docCurrency: "KES",
    options: {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  }),
  watch: {
    "$route.params.objectId": {
      handler: "setActiveObject",
      immediate: true,
    },
    "$route.params.id": {
      handler: "setActiveDocumentID",
      immediate: true,
    },
  },
  computed: {
    headRowsItemDetails() {
      if (this.record.DocType == "I") {
        if (this.activeObject == 13 || this.activeObject == 15) {
          return [
            {
              count: "#",
              itemCode: "Item Code",
              description: "Description",
              Quantity: "Qty",
              WhsCode: "Whse",
              PriceBDisc: "PriceBDisc",
              Disc: "Disc %",
              Price: "Price",
              Tax: "Tax %",
              total_amount: "Total",
            },
          ];
        }

        return [
          {
            count: "No",
            itemCode: "Item Code",
            description: "Description",
            Quantity: "Quantity",
            Price: "Price",
            Disc: "Disc %",
            Tax: "Tax %",
            total_amount: "Total",
          },
        ];
      }

      return [
        {
          count: "#",
          description: "Description",
          Price: "Price",
          total_amount: "Total",
        },
      ];
    },
    loggedInUser() {
      return this.$store.state.user;
    },
  },
  methods: {
    formatDate(data) {
      return moment(data).format("DD/MM/YYYY");
    },
    setActiveObject(objectId) {
      this.activeObject = objectId;
      if (objectId == 17) {
        this.objectName = "SALES ORDER";
        this.DocName = "Order Number";
      }
      if (objectId == 13) {
        this.objectName = "TAX INVOICE/DELIVERY NOTE";
        this.DocName = "Invoice Number";
      }
      if (objectId == 15) {
        this.objectName = "PACKAGING LIST";
        this.DocName = "Delivery Number";
      }
      if (objectId == 16) {
        this.objectName = "SALES RETURN";
        this.DocName = "Return Number";
      }
      if (objectId == 14) {
        this.objectName = "CREDIT NOTE";
        this.DocName = "Credit Note No.";
      }
      if (objectId == 205) {
        this.objectName = "Purchase Request";
        this.DocName = "Purchase Request";
      }
      if (objectId == 66) {
        this.objectName = "Inventory Transfer Request";
        this.DocName = "Inventory Transfer Request";
      }
    },
    setActiveDocumentID(docEntry) {
      this.activeDocumentID = docEntry;
    },

    getDocs() {
      var isDoc = this.$route.query.isDoc;
      this.$store.commit("loader", true);
      if (this.activeObject !== null) {
        const objectId = this.activeObject;
        const id = this.activeDocumentID;
        if (id) {
          const self = this;
          //get document data
          this.$store
            .dispatch("get", `/documents/${objectId}/${id}?isDoc=${isDoc}&isForPrint=1`)
            .then((res) => {
              self.record = res;
              self.tableData = res.document_lines;
              // this.$store.commit("loader", false);
              if (objectId == 13) {
                if (res.U_QRCode != "") {
                  this.$store.commit("loader", false);
                  self.printDoc();
                } else {
                  this.$refs.snackbar.show("Posting Invoice to Kra.", "green");
                  const timsPayload = [];
                  timsPayload.push(res.timsPayload);
                  axios
                    .post(self.loggedInUser.oadm.TimsUrl, timsPayload, this.options)
                    .then((timsResp) => {
                      if (timsResp.data.statusCode == 0) {
                        this.$store.commit("loader", false);
                        self.fiscalizeSuccessDialog = true;
                        self.successMsg = "Fiscalization Complete. Print Document";
                      } else {
                        this.$store.commit("loader", false);
                        self.fiscalizeErrorDialog = true;
                        self.errorMsg =
                          timsResp.data.error + "Preview Layout without tims qr-code?";
                      }
                    })
                    .catch((error) => {
                      this.$store.commit("loader", false);
                      self.fiscalizeErrorDialog = true;
                      self.errorMsg =
                        "An error occurred when posting invoice to tims. Preview Layout without kra qr-code?";
                      console.log(error);
                    });
                }
              } else {
                self.printDoc();
              }
              // this.printOnThermal();
              // this.printDoc();
            })
            .catch((err) => {
              this.$store.commit("loader", false);
              console.log(err);
            });
        }
      }
    },
    printDoc() {
      /**
       * Load Document For printing
       */
      const self = this;

      self.fiscalizeErrorDialog = false;
      self.fiscalizeSuccessDialog = false;

      var doc = new jsPDF({ compress: true });
      /**
       * Constants
       */

      const documentDefaultFontSize = 8;
      let withCustomerDetails = true;

      if (self.activeObject == 205) {
        withCustomerDetails = false;
      }

      if (self.activeObject == 66) {
        withCustomerDetails = false;
      }

      /**
       * Document Current
       */

      if (self.record == null) {
        this.$refs.snackbar.show("Record Loading.", "red");
        return;
      }
      var objectName = this.objectName;

      if (this.activeObject == 17) {
        this.docCurrency = this.record.business_partner.Currency;
        if (
          this.record.CardCode.endsWith("C00015") ||
          this.record.CardCode.endsWith("C00015D")
        ) {
          this.docCurrency = "KES";
        }
      }
      if (this.activeObject == 15) {
        if (this.record.U_SaleType == "INTERCOMPANY") {
          objectName = "INTERCOMPANY DELIVERY NOTE";
        }
        this.docCurrency = this.record.business_partner.Currency;
      }

      if (this.activeObject == 13) {
        this.docCurrency = this.record.business_partner.Currency;
        if (this.record.CardCode.endsWith("C00015")) {
          objectName = "CASH SALE / DELIVERY NOTE";
        }
        if (
          this.record.CardCode.endsWith("C00015") ||
          this.record.CardCode.endsWith("C00015D")
        ) {
          this.docCurrency = "KES";
        }
      }

      var requestType = self.record.U_PCash;
      if (self.activeObject == 205 && requestType == "Petty Cash") {
        objectName = "PETTY CASH REQUEST";
      }
      if (self.activeObject == 205 && requestType == "Purchase Request") {
        objectName = "PURCHASE REQUEST";
      }
      if (self.activeObject == 205 && requestType == "EFT/Cheque Request") {
        objectName = "EFT/CHEQUE REQUEST";
      }
      if (self.activeObject == 205 && requestType == "IOU Clearance") {
        objectName = "IOU CLEARANCE";
      }
      if (self.activeObject == 205 && requestType == "Expense Claim") {
        objectName = "EXPENSE CLAIM";
      }
      if (self.activeObject == 205 && requestType == "Casual Wages") {
        objectName = "CASUAL WAGES";
      }
      if (self.activeObject == 205 && requestType == "Cheque Payment Voucher") {
        objectName = "CHEQUE PAYMENT VOUCHER";
      }
      if (self.activeObject == 205 && requestType == "Petty Cash IOU Request") {
        objectName = "PETTY CASH IOU REQUEST";
      }

      doc.setFont("Georgia, serif", "bold");
      doc.setFontSize(14);
      var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      var totalPagesExp = "{total_pages_count_string}";

      /*
            Constnats
        */
      const textFontSize = 8;
      //const tableTextFontSize = 10;
      /*
        LOGO
      */
      var img = new Image();
      img.src = require("@/assets/logocg.png");
      doc.addImage(img, "png", 10, 11, 55, 30);

      doc.text(objectName, pageWidth / 2, 10, "center");
      doc.line(5, 11, pageWidth - 5, 11); // Top Line
      doc.setFontSize(10);
      if (this.record.Printed == "N") {
        doc.text("Original", pageWidth - 20, 14);
      }

      if (this.record.Printed == "Y") {
        doc.text("Copy", pageWidth - 20, 14);
      }
      if (self.record.branch) {
        doc.setFontSize(10);
        if (this.activeObject != 205) {
          doc.text(
            "CAR & GENERAL TRADING LIMITED -" +
              self.record.branch.branch_address.reportFullName.toUpperCase(),
            pageWidth / 2 - 35,
            16
          );
        }
        if (this.activeObject == 205) {
          doc.text("CAR & GENERAL TRADING LIMITED ", pageWidth / 2 - 35, 16);
        }
        doc.setFontSize(8);
        doc.text(
          self.record.branch ? self.record.branch.branch_address.addressDetails : " ",
          pageWidth / 2 - 35,
          22
        );
        doc.text(
          self.record.branch ? self.record.branch.branch_address.address2 : " ",
          pageWidth / 2 - 35,
          25
        );
        doc.text(
          self.record.branch ? self.record.branch.branch_address.address3 : " ",
          pageWidth / 2 - 35,
          28
        );
        doc.text(
          self.record.branch ? self.record.branch.branch_address.phoneNumber : " ",
          pageWidth / 2 - 35,
          31
        );
        doc.text(
          self.record.branch ? self.record.branch.branch_address.Mobile : " ",
          pageWidth / 2 - 35,
          34
        );

        doc.text("PIN: P051096263G", pageWidth / 2 - 35, 37);
        doc.text("VAT NO:0108334E", pageWidth / 2 - 35, 40);
      }
      /*
       Line Below Image
      */

      doc.line(5, 42, pageWidth - 5, 42);

      /*
      -------------------------------------------------------------------------------
                    BEGINNING OF CUSTOMER DETAILS AND DOCUMENT DETAILS
       -----------------------------------------------------------------------------
      */
      /**
       * Customer Details
       */

      /**QR Code - KRA QR Code */
      var imgQRcode = new Image();
      imgQRcode.src = self.record.qrcode;
      // doc.addImage(imgQRcode, "JPEG", pageWidth / 2 - 10, 50, 30, 30);
      doc.addImage(imgQRcode, "JPEG", pageWidth / 2 - 15, 50, 30, 30);
      // doc.text("Serial No.".this.record.CardCode, pageWidth / 2 - 15, 85);
      // doc.text(this.record.CardCode, pageWidth / 2 - 15, 85);
      doc.addFont("Tahoma");
      doc.setFont("Tahoma", "bold");
      doc.setFontSize(10);

      if (withCustomerDetails) {
        doc.text("Customer", 10, 49);
        doc.line(10, 50, 27, 50);
      }
      if (self.activeObject == 205) {
        doc.text("Requester", 10, 49);
        doc.line(10, 50, 27, 50);
      }
      doc.setFontSize(textFontSize);

      if (withCustomerDetails) {
        doc.text(this.record.CardCode, 10, 53);
        doc.text(this.record.CardName, 10, 58);

        if (this.record.U_CashName) {
          doc.text(this.record.U_CashName, 10, 63);
        }
        if (this.record.business_partner.Address) {
          doc.text(this.record.business_partner.Address, 10, 67);
        }
        if (this.record.U_CashNo) {
          doc.text(this.record.U_CashNo, 10, 70);
        }
        if (this.record.LicTradNum) {
          doc.text("KRA PIN:", 10, 74);
          doc.text(this.record.LicTradNum, 23, 74);
        }

        if (this.record.U_IDNo) {
          doc.text("ID No:", 10, 78);
          doc.text(this.record.U_IDNo, 22, 78);
        }
      }

      if (this.activeObject == 205) {
        doc.setFont("Tahoma, serif", "bold");
        doc.setFontSize(9);
        doc.text("Staff No :" + "" + this.record.Requester, 10, 53);
        doc.text("Name     :" + "" + this.record.ReqName, 10, 57);
        doc.text(
          this.record.department ? "Department :" + "" + this.record.department.Name : "",
          10,
          60
        );
        doc.text("Email :" + "", 10, 63);
      }

      /**
       * Document Details
       */
      function headRowsRefDetails() {
        return [
          {
            count: "",
            name: "",
            Date: "",
            createDate: "",
            Received: "",
            Line: "",
          },
        ];
      }

      const doc_details = [];
      const docNumber = {
        count: this.DocName,
        name: this.record.ExtRefDocNum,
      };
      const docDate = {
        count: "Document Date",
        name: this.formatDate(this.record.DocDate),
      };
      const leyscoNo = {
        count: "Pos No.",
        name: this.record.DocNum,
      };
      const cuInvoceNo = {
        count: "CU Invoice No.",
        name: this.record.U_CUInvoiceNum,
      };
      doc_details.push(docNumber);
      doc_details.push(cuInvoceNo);
      doc_details.push(leyscoNo);
      doc_details.push(docDate);

      if (this.activeObject == 13) {
        const docDueDate = {
          count: "Due Date",
          name: this.formatDate(this.record.DocDueDate),
        };
        doc_details.push(docDueDate);
      }

      if (this.activeObject != 205) {
        const reference = {
          count: "Your Reference",
          name: this.record.NumAtCard,
        };
        const deliveryMode = {
          count: "Delivery Mode",
        };
        const contactPerson = {
          count: "Contact Person",
          name: this.record.oslp ? this.record.oslp.SlpName : null,
        };
        doc_details.push(reference);
        doc_details.push(deliveryMode);
        doc_details.push(contactPerson);
      }

      doc.autoTable({
        head: headRowsRefDetails(),
        body: doc_details,
        theme: "plain",
        tableWidth: 90,
        columnStyles: {
          count: {
            fontStyle: "bold",
            font: "Tahoma",
            halign: "left",
          },
          name: {
            font: "Tahoma",
            halign: "right",
          },
        },
        startY: 38,
        styles: {
          overflow: "hidden",
          fontSize: documentDefaultFontSize,
        },
        margin: { left: 130 },
      });

      /*
      -------------------------------------------------------------------------------
                    Watermark
       -----------------------------------------------------------------------------
      */

      function addWaterMark(doc) {
        var i;
        var totalPages = doc.internal.getNumberOfPages();
        for (i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setTextColor("#808080");
          doc.setFontSize(36);
          doc.saveGraphicsState();
          doc.setGState(new doc.GState({ opacity: 0.2 }));
          doc.text(60, 180, "FOR INTERNAL USE", null, 45);
          doc.restoreGraphicsState();
        }
        doc.setFont("Georgia, serif", "bold");
        doc.setFontSize(8);
        doc.setTextColor("#000000");

        return doc;
      }
      /*
      -------------------------------------------------------------------------------
                    BEGINNING OF ITEM DETAILS
       -----------------------------------------------------------------------------
      */

      const itemDetails = [];
      var countItem = 1;
      function convert(n) {
        n = String(n);
        if (n.length == 1) {
          n = "00" + n;
        }
        if (n.length == 2) {
          n = "0" + n;
        }
        return n;
      }
      for (const note of this.tableData) {
        // console.log("Note", note);
        const itemDetail = {
          count: convert(countItem),
          itemCode: note.ItemCode,
          description: note.Dscription,
          Quantity: note.Quantity,
          WhsCode: note.WhsCode,
          PriceBDisc: note.formattedPriceBefDisc,
          Disc: note.DiscPrcnt,
          Price: note.formattedPrice,
          Tax: note.taxgroup ? note.taxgroup.rate : 0,
          total_amount: note.formattedLineTotal,
        };
        itemDetails.push(itemDetail);
        if (note.SerialNumbers && note.SerialNumbers.length > 0) {
          for (const serial of note.SerialNumbers) {
            const itemSerial = {
              count: serial.osrn,
            };

            itemDetails.push(itemSerial);
          }
        }
        countItem = countItem + 1;
      }

      // console.log("Item Detaisl", itemDetails);
      var body = [];
      for (var i = 0; i < itemDetails.length; i++) {
        var row = [];
        for (var key in itemDetails[i]) {
          // console.log(itemDetails[i][key], "FASDFASD");
          row.push(itemDetails[i][key]);
        }
        const isIteSerial = itemDetails[i]["itemCode"];
        if (isIteSerial === undefined) {
          const serialData = itemDetails[i]["count"];
          const serialNumber = serialData.DistNumber ?? "";
          const numberPlate = serialData.MnfSerial ?? "";
          const engineNo = serialData.LotNumber ?? "";

          const serialDataDetails =
            "Serial No./Chasin No :: " +
            serialNumber +
            "    ||   Number Plate :: " +
            numberPlate +
            "    ||  Engine No :: " +
            engineNo;

          row.unshift({
            colSpan: 10,
            content: serialDataDetails,
            styles: { valign: "middle", halign: "center" },
          });
        }
        body.push(row);
      }

      if (this.activeObject == 205 || this.record.DocType == "S") {
        body = itemDetails;
      }

      if (this.activeObject == 66) {
        body = itemDetails;
      }
      doc.autoTable({
        head: this.headRowsItemDetails,
        body: body,
        theme: "grid",
        headStyles: {
          fillColor: "#808080",
          fontSize: 7,
        },
        bodyStyles: {
          textColor: "#000000",
          fontSize: documentDefaultFontSize,
        },
        tableWidth: pageWidth - 20,
        columnStyles: {
          total_amount: {
            halign: "right",
          },
          styles: {
            font: "Tahoma",
            fontSize: documentDefaultFontSize,
          },
        },

        allSectionHooks: true,

        margin: { left: 10 },

        startY: doc.autoTable.previous.finalY + 5,
      });

      /*
      -------------------------------------------------------------------------------
                   WaterMark
       -----------------------------------------------------------------------------
      */

      if (this.activeObject == 17) {
        doc = addWaterMark(doc);
      }

      /*
      -------------------------------------------------------------------------------
                    BEGINNING FOOTER ITEM DETAILS
       -----------------------------------------------------------------------------
      */

      /**
       * Payment Term Details
       */

      /**
       * Gate Pass QR Code
       */

      var GatePassQRcode = new Image();
      GatePassQRcode.src = self.record.gatepass_qrcode;
      doc.addImage(
        GatePassQRcode,
        "JPEG",
        pageWidth / 2 - 10,
        doc.autoTable.previous.finalY + 30,
        30,
        30
      );

      var StartOfOtherTables = doc.autoTable.previous.finalY + 30;
      const paymentTermDetails = [];
      const remarks = {
        count: "Remarks",
        name: self.record.Comments,
      };
      paymentTermDetails.push(remarks);
      if (withCustomerDetails) {
        const paymentTerm = {
          count: "Payment Term:",
          name: self.record.business_partner.octg
            ? self.record.business_partner.octg.PymntGroup
            : "N/A",
        };
        paymentTermDetails.push(paymentTerm);
        // doc.line(10, 173, 55, 173);
      }

      doc.autoTable({
        head: headRowsRefDetails(),
        body: paymentTermDetails,
        theme: "plain",
        tableWidth: 120,
        bodyStyles: {
          textColor: "#000000",
          fontSize: 7,
        },
        columnStyles: {
          count: {
            fontStyle: "bold",
            font: "Tahoma",
          },
          name: {
            halign: "left",
            fontStyle: "regular",
            cellWidth: "auto",
          },
        },
        columnWidth: "wrap",
        startY: StartOfOtherTables,
        styles: {
          overflow: "hidden",
          fontSize: 7,
        },
        margin: { left: 10 },
      });

      /**
       * Document Details Totals
       */
      const documentTotalDetails = [];
      const docSubTotal = {
        count: "Doc Subtotal:",
        name: this.docCurrency + " " + this.record.formattedSubDocTotal,
      };
      const totalBeforeTax = {
        count: "Total Before Tax:",
        name: this.docCurrency + " " + self.record.formattedDocTotalBeforeTax,
      };
      const totalTaxAmount = {
        count: "Total Tax Amount: ",
        name: this.docCurrency + " " + self.record.formattedVatSum,
      };
      const totalAmount = {
        count: "Total Amount:",
        name: this.docCurrency + " " + self.record.formattedDocTotal,
      };

      documentTotalDetails.push(docSubTotal);
      documentTotalDetails.push(totalBeforeTax);
      documentTotalDetails.push(totalTaxAmount);
      documentTotalDetails.push(totalAmount);

      doc.autoTable({
        head: headRowsRefDetails(),
        body: documentTotalDetails,
        theme: "plain",
        tableWidth: 70,
        pageBreak: "auto",
        rowPageBreak: "avoid",
        columnStyles: {
          count: {
            halign: "left",
          },
          name: {
            fontStyle: "bold",
            halign: "right",
          },
        },
        bodyStyles: {
          textColor: "#000000",
          fontSize: documentDefaultFontSize,
        },
        startY: StartOfOtherTables,
        styles: {
          overflow: "hidden",

          fontSize: documentDefaultFontSize,
        },
        margin: { left: 146 },
      });

      /**
       * Line After Doc Total
       *
       */
      doc.setDrawColor(0, 0, 0);
      doc.setFont("Tahoma", "bold");
      doc.line(
        5,
        doc.autoTable.previous.finalY + 1,
        pageWidth - 5,
        doc.autoTable.previous.finalY + 1
      );

      /**
       * Document Remarks
       *
       */

      const FirstDocRemark = [];
      const rowPreparedBy = {
        count: "Prepared By",
        name: self.record.created_by.name,
        Date: "Date",
        createDate: this.formatDate(this.record.DocDate),
        Received: "Received By",
        Line: "________________",
      };
      FirstDocRemark.push(rowPreparedBy);

      doc.autoTable({
        head: headRowsRefDetails(),
        body: FirstDocRemark,
        theme: "plain",
        tableWidth: 200,
        columnStyles: {
          count: {
            fontStyle: "bold",
            font: "Tahoma",
          },
          Received: {
            fontStyle: "bold",
            font: "Tahoma",
          },
          Date: {
            fontStyle: "bold",
            font: "Tahoma",
          },
          bodyStyles: { minCellHeight: 40 },
        },
        startY: doc.autoTable.previous.finalY - 2,
        styles: {
          overflow: "hidden",
          fontSize: documentDefaultFontSize,
        },
        margin: { left: 6 },
      });

      function headRowsDocRemarksfDetails() {
        return [
          {
            Column1: "",
            Sign1: "",
            Column2: "",
            Sign2: "",
            Sign3: "",
          },
        ];
      }
      const docRemarkDetails = [];
      if (this.record.approvers !== undefined) {
        for (const approver of this.record.approvers) {
          console.log("approver.userDetails");
          console.log(approver.userDetails);
          if (approver.userDetails != null) {
            var userCode = approver.userDetails.account;
            var path = approver.imagePath;
            doc[`${userCode}`] = path;
            const row2DocRemark = {
              Column1: "Approved By",
              Sign1: approver.userDetails.name,
              Column2: "Approved Date",
              Sign2: approver.UpdateDate,
              Sign3: null,
            };

            docRemarkDetails.push(row2DocRemark);
          }
        }

        doc.approvers = this.record.approvers;
      }
      doc.autoTable({
        head: headRowsDocRemarksfDetails(),
        body: docRemarkDetails,
        theme: "plain",
        tableWidth: 170,
        columnStyles: {
          Column1: {
            fontStyle: "bold",
          },
          Column2: {
            fontStyle: "bold",
          },
        },
        startY: doc.autoTable.previous.finalY + 6,
        styles: {
          overflow: "hidden",
          font: "Tahoma",
        },
        bodyStyles: {
          minCellHeight: 40,
          textColor: "#000000",
          fontSize: documentDefaultFontSize,
        },

        margin: { left: 6 },
        didDrawCell: function (data) {
          var img = new Image();
          if (data.column.index === 4 && data.cell.section === "body") {
            const approverDetails = doc.approvers[data.row.index];
            if (typeof approverDetails !== "undefined") {
              img.src = approverDetails.imagePath;
              var dim = data.cell.height - data.cell.padding("vertical");

              doc.addImage(img, "JPEG", data.cell.x, data.cell.y - 15, dim - 8, dim - 8);
            }
          }
        },
      });

      /**
       * Received By Tables
       */

      const secondDocRemark = [];
      if (this.activeObject != 205) {
        const row3DocRemark = {
          count: "Approved By",
          name: "__________________________",
          Date: "Date",
          createDate: "_____________________",
          Received: "ID",
          Line: "________________",
        };
        secondDocRemark.push(row3DocRemark);
      }

      doc.autoTable({
        head: headRowsRefDetails(),
        body: secondDocRemark,
        theme: "plain",

        columnStyles: {
          count: {
            fontStyle: "bold",
            font: "Tahoma",
          },
          Date: {
            fontStyle: "bold",
            font: "Tahoma",
          },
          Received: {
            fontStyle: "bold",
            font: "Tahoma",
          },

          bodyStyles: { minCellHeight: 40 },
        },
        bodyStyles: {
          textColor: "#000000",
          fontSize: 7,
        },
        startY: doc.autoTable.previous.finalY - 5,
        styles: {
          overflow: "hidden",
          fontSize: documentDefaultFontSize,
        },
        margin: { left: 10 },
        didDrawPage: function (data) {
          // Footer
          var str = "Page " + doc.internal.getNumberOfPages();
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }

          doc.setFontSize(8);
          doc.setTextColor(0, 0, 0);

          // jsPDF 1.4+ uses getWidth, <1.4 uses .width
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(str, data.settings.margin.right, pageHeight - 10);

          doc.setFontSize(8);
          doc.text(
            "Document generated by Leysco100-Car&General POS",
            pageWidth / 2,
            pageHeight - 10,
            "center"
          );
        },
      });

      /**
       * Final Remark
       */

      if (this.activeObject != 205) {
        doc.text(
          `Payments above ksh 1M(One Million) or US$ 10,000/ is paid through Real(RTGS) Bank Details STANDARD CHARTERED BANK, Moi Avenue,A/C 0104003231500, Currency: `,
          10,
          doc.autoTable.previous.finalY + 5,
          doc.setFont("Tahoma"),
          doc.setFontSize(7)
        );

        doc.text(
          ` KES, A/C 8704090013700, Currency:USD, SWIFT CODE:SCBLKENXXXX. All cheques are payable to CAR & GENERAL TRADING LTD`,
          10,
          doc.autoTable.previous.finalY + 10,
          doc.setFont("Tahoma"),
          doc.setFontSize(7)
        );
      }

      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }

      this.$store.commit("loader", false);
      this.updateDocumentPrintedStatus();

      window.open(doc.output("bloburl"), "_blank");
    },

    //Print On thermal

    printOnThermal() {
      const url = "http://localhost/print-receipt/src/index.php";
      axios.post(url, this.record).then(() => {
        this.$store.commit("loader", false);
      });
    },

    /**
     * Updating Printing Status Of the document
     */
    updateDocumentPrintedStatus() {
      const activeObject = this.activeObject;
      const data = this.record;
      const url = "marketing-doc-printed/" + activeObject + "/" + this.activeDocumentID;

      this.$store
        .dispatch("post", { url, data })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    // this.getData();
    this.$store.commit("loader", false);
  },
};
</script>
